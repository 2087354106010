// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import '../stylesheets/application';
// bootstrap用
import 'bootstrap';
import '../stylesheets/application';

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

setTimeout(() => {
  const flash = document.getElementById('flash');
  if (flash) {
    flash.classList.add('d-none');
  }
}, 2000);

// タブの切り替え
$(document).on('turbolinks:load', function () {
  $(function () {
    $('.tab').click(function () {
      $('.tab-active').removeClass('tab-active');
      $(this).addClass('tab-active');
      $('.box-show').removeClass('box-show');
      const index = $(this).index();
      $('.tabbox').eq(index).addClass('box-show');
    });
  });
});

// turbolinksの無効化
$(document).on('turbolinks:load', function () {
  $(function () {
    // .tabがクリックされたときを指定
    $('.tab').click(function () {
      // 今ある.tab-activeを削除
      $('.tab-active').removeClass('tab-active');
      // クリックされた.tabに.tab-activeを追加
      $(this).addClass('tab-active');
      // 今ある.box-showを削除
      $('.box-show').removeClass('box-show');
      // indexに.tabのindex番号を代入
      const index = $(this).index();
      // .tabboxとindexの番号が同じ要素に.box-showを追加
      $('.tabbox').eq(index).addClass('box-show');
    });
  });
});

// #page-topをクリックした際の設定
$('#page-top').click(function () {
  $('body,html').animate(
    {
      scrollTop: 0, //ページトップまでスクロール
    },
    500
  ); //ページトップスクロールの速さ。数字が大きいほど遅くなる
  return false; //リンク自体の無効化
});

require('@rails/activestorage').start();
require('trix');
require('@rails/actiontext');
import './modal';
import './link';
import './top-header';
import './change-status';
import './trix-editor-overrides';
