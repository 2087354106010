document.addEventListener('turbolinks:load', () => {
  $('#g-nav a').click(function () {
    //ナビゲーションのリンクがクリックされたら
    $('.openbtn').removeClass('active'); //ボタンの activeクラスを除去し
    $('#g-nav').removeClass('panelactive'); //ナビゲーションのpanelactiveクラスも除去
  });

  $('.close-button').click(function () {
    //ナビゲーションのリンクがクリックされたら
    const top = document.body.style.top.replace('px', '');
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo({
      top: parseInt(top || '0'),
      left: 0,
      behavior: 'auto',
    });
    setTimeout(() => {
      $('.openbtn').removeClass('active'); //ボタンの activeクラスを除去し
      $('#g-nav').removeClass('panelactive'); //ナビゲーションのpanelactiveクラスも除去
    }, 300);
  });

  let openbtn = document.getElementById('openbtn');
  let gnav = document.getElementById('g-nav');
  const body = document.body;
  openbtn.addEventListener('click', function () {
    this.classList.toggle('active');
    gnav.classList.toggle('panelactive');
    body.style.top = `${window.scrollY}px`;
    setTimeout(() => {
      body.style.position = 'fixed';
    }, 900);
  });
});
