window.addEventListener('load', function () {
  const domain = document.domain;
  const regexp = new RegExp(domain);

  const target = document.getElementById('content');

  if (target) {
    const elements = target.getElementsByTagName('a');
    for (let element of elements) {
      let href = element.getAttribute('href');

      // ページ内リンクを除外する
      if (!regexp.test(href) && href[0] != '#') {
        element.setAttribute('target', '_blank');
        element.setAttribute('rel', 'noopener noreferrer');
      }
    }
  }
});
